import { Routes, Route } from 'react-router-dom';
import LandingPage from './views/LandingPage';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<LandingPage />} />
    </Routes>
  );

};

export default Router;