import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { EMAIL, LINE_ID } from "../constans/app.constans";
import EmailImg from "../assets/images/socials/email.png";
import LineImg from "../assets/images/socials/line.png";
import LineQR from "../assets/images/socials/9853.png";

const Card = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: '#232233',
    lineHeight: '38px',
    fontSize: '25px',
    fontWeight: 600,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    borderRadius: '30px',
  }));

const OverlayContact = () => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Card sx={{ 
            display: 'flex', 
            flexDirection: "column", 
            justifyContent: "center", 
            alignItems: "center",
            width: xs ? '342px' : '1170px',
            height: xs ? '310px' : '230px',
            top: xs ? '-155px' : '-115px',
            }} elevation={8}>
            <Stack sx={{ justifyContent: {xs: "center", md: "center"}, alignItems: { xs: "center", md: "center" }, height: xs ? "310px" : "117px", width: xs ? "342.76px" : "100%" }} direction={ xs ? "column" : "row" } spacing={2}>
                <Box sx={{ px: 6, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center", justifyContent: "flex-start", width: "50%" }}>
                    <img style={{ width: xs ? "50px" : "100px"}} className="" src={EmailImg} alt="EmailImg" />
                    <Button href={`mailto:${EMAIL}`} sx={{ textTransform: "unset", fontSize: "25px", color: "#232233" }} >
                        {EMAIL}                
                    </Button>
                </Box>
                <Box sx={{width: xs ? '117px' : 'inherit', display: xs ? 'block': 'none',}}>
                    <Divider sx={{ border: "1px solid #6C6C72", background: "#6C6C72", }} variant="middle" orientation={ xs ? "horizontal" : "vertical"} flexItem />
                </Box>
                <Divider sx={{ display: xs ? "none": "block", border: "1px solid #6C6C72", background: "#6C6C72", }} variant="middle" orientation={ xs ? "horizontal" : "vertical"} flexItem />
                <Box sx={{ px: 6, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center", justifyContent: "flex-start", width: "50%", position: "relative" }}>
                    <img style={{ width: xs ? "50px" : "100px"}} className="" src={LineImg} alt="LineImg" />
                    <Button href={`https://line.me/ti/p/~${LINE_ID}`} target="_blank" sx={{ textTransform: "unset", fontSize: "25px", color: "#232233" }} >
                        {LINE_ID}
                    </Button>
                    <img style={{ display: xs ? "none" : "unset",  position: "absolute", right: 6 }} src={LineQR} alt="LineQR" />
                </Box> 
            </Stack>
        </Card>
    );
}

export default OverlayContact;