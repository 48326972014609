export const APP_STORE_LINK = "https://apps.apple.com/th/app/hangles/id1626057999";
export const GOOGLE_APP_LINK = "https://play.google.com/store/apps/details?id=com.app.hangles.poc_hangles&hl=en";

export const BUTTON1_LINK = "#";
export const BUTTON2_LINK = "#";
export const BUTTON3_LINK = "https://web.hangles.com/appwebpage";

export const EMAIL = "support@hangles.co.th";
export const LINE_ID = "@hangles";
export const FACEBOOK = "https://www.facebook.com/Hangles.co";
export const INSTAGRAM = "https://www.instagram.com/hangles.co";
export const TWITTER = "https://twitter.com/hangles_co";
export const TIKTOK = "https://www.tiktok.com/@hangles.co";

export const SUPPORT_LINK = "https://www.hangles.com/hcenter";
export const LINE_LINK = "https://lin.ee/YqqFQoL";
export const PRIVACY_LINK = "https://www.hangles.com/support/policys?pid=2";
export const TERM_AND_CON_LINK = "https://www.hangles.com/support/policy";
export const FAQ_LINK = "https://www.hangles.com/hcenter/faq";
export const VISIT_OUR_STORE = "https://www.hangles.com/feed";