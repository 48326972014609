import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CopyrightIcon from '@mui/icons-material/Copyright';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hangles from "../assets/images/hangles-white-1.png";
import Facebook from "../assets/images/socials/facebook.png";
import Instagram from "../assets/images/socials/instagram-fill.png";
import Twitter from "../assets/images/socials/twitter-fill.png";
import Tiktok from "../assets/images/socials/tiktok.png";
import OverlayContact from './OverlayContact'
import { SUPPORT_LINK,LINE_LINK, PRIVACY_LINK, TERM_AND_CON_LINK, FACEBOOK, INSTAGRAM, TWITTER, TIKTOK, VISIT_OUR_STORE } from '../constans/app.constans'

const SocialShare = () => {
    return (
        <Stack sx={{ justifyContent: "center" }} direction="row" spacing={2}>
            <Button href={`${FACEBOOK}`} sx={{color: "#FFFFFF", textTransform: "unset" }} target="_blank">
                <img className="" src={Facebook} alt="Facebook" />
            </Button>
            <Divider sx={{ border: "0.5px solid #FFFFFF" }} variant="middle" orientation="vertical" flexItem />
            <Button href={`${INSTAGRAM}`} sx={{color: "#FFFFFF", textTransform: "unset" }} target="_blank">
                <img className="" src={Instagram} alt="Instagram" />
            </Button>
            <Divider sx={{ border: "0.5px solid #FFFFFF" }} variant="middle" orientation="vertical" flexItem />
            <Button href={`${TWITTER}`} sx={{color: "#FFFFFF", textTransform: "unset" }} target="_blank">
                <img className="" src={Twitter} alt="Twitter" />
            </Button>
            <Divider sx={{ border: "0.5px solid #FFFFFF" }} variant="middle" orientation="vertical" flexItem />
            <Button href={`${TIKTOK}`} sx={{color: "#FFFFFF", textTransform: "unset" }} target="_blank">
                <img className="" src={Tiktok} alt="Tiktok" />
            </Button>
        </Stack>
    );
}

const Footer = () => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <footer style={{ backgroundColor: "#11D7AF", position: "relative" }}>
            <OverlayContact />
            <Box sx={{ mt: { xs: 4, md: 0}, pt: { xs: 25, md: 20 }, pb: { sm: 2, md: 2 }, display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "column" }}>
                <Grid sx={{ px: {xs: 2,  md: 10},mb: 3 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid sx={{ p: 0, textAlign: xs ? "left" : "center" }} item xs={12} md={4}>
                        <img className="" src={Hangles} alt="Hangles" />
                    </Grid>
                    <Grid sx={{ p: 0, textAlign: "center" }} item xs={12} md={4} order={xs ? 1 : 0}>
                        <Stack sx={{ mt: { xs: 2, md: 0},justifyContent: {xs: "flex-start !important", md: "center"}, alignItems: { xs: "flex-start !important", md: "center" } }} direction={ xs ? "column" : "row"} spacing={2}>
                            <Button href={LINE_LINK} sx={{ marginTop: "0 !important", color: "#FFFFFF", textTransform: "unset" }} target="_blank">Support</Button>
                            <Button href={PRIVACY_LINK} sx={{ marginTop: "0 !important", color: "#FFFFFF", textTransform: "unset" }} target="_blank">Privacy Policy</Button>
                            <Button href={TERM_AND_CON_LINK} sx={{ marginTop: "0 !important", color: "#FFFFFF", textTransform: "unset" }} target="_blank">Terms and Conditions</Button>
                        </Stack>
                        <Stack sx={{ mt: { xs: 2, md: 0},justifyContent: {xs: "flex-start !important", md: "center"}, alignItems: { xs: "flex-start", md: "center" } }} direction={ xs ? "column" : "row"} spacing={2}>
                             <Button href={VISIT_OUR_STORE} sx={{ marginTop: "0 !important", color: "#FFFFFF", textTransform: "unset" }} target="_blank">Visit our store</Button>
                        </Stack>
                    </Grid>
                    <Grid sx={{ mt: { xs: 2, md: 0}, p: 0, textAlign: "center" }} item xs={12} md={4}>
                        <SocialShare />
                    </Grid>
                </Grid>
                <Divider sx={{ width: "90%", border: "0.5px solid #FFFFFF"}} />
                <Typography sx={{ mt: 3, display: "flex", width: "200", textAlign: "center", color: "#FFFFFF", fontSize: { xs: "12.8px", md: "16px"} }} variant="inherit" component="p" gutterBottom>
                    <CopyrightIcon sx={{ px: 2}} /> Copyright 2022 .Hangles. All Right Reserved.
                </Typography>
            </Box>
        </footer>
    );
}

export default Footer;