import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Data from '../assets/content.json'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LandingImage from '../assets/images/main1.jpeg';
import LandingImageMobile from '../assets/images/landing-photo-mobile.png';
import Apple from '../assets/images/Apple@2x.png';
import Google from "../assets/images/Google@2x.png";
import App1 from "../assets/images/app1@2x.png";
import App2 from "../assets/images/app2.png";
import CheckY from '../assets/images/Check-Y.png'
import CheckP from '../assets/images/Check-P.png'

import { APP_STORE_LINK, GOOGLE_APP_LINK, BUTTON1_LINK, BUTTON2_LINK, BUTTON3_LINK } from "../constans/app.constans";
import Footer from "../components/Footer";

const ButtonSell = (params: any) => {
   return (<Button href={BUTTON1_LINK} target="_blank" sx={{
      position: params.xs ? "unset": "absolute",
      top: '6rem',
      left: '4rem',
      color: '#FFFFFF',
      backgroundColor: '#4A67FE',
      borderRadius: '10px',
      boxShadow: '0px 32px 34px rgba(28, 27, 32, 0.198973)',
      height: '63px',
      padding: '0 25px',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '21px',
      textTransform: "unset",
      ':hover': {
          backgroundColor: '#3751dc',
      },
     }}>                        
        <img style={{ marginRight: "14px", objectFit: "cover", objectPosition: "center" }} src={CheckY} alt="check" />
        {params?.state?.contentButton1}
  </Button>);
}

const ButtonBuy  = (params: any) => {
   return (<Button href={BUTTON2_LINK} target="_blank" sx={{
      position: params.xs ? "unset": "absolute",
      right: 0,
      bottom: '12em',
      color: '#FFFFFF',
      backgroundColor: '#F48C7D',
      borderRadius: '10px',
      boxShadow: '0px 32px 34px rgba(28, 27, 32, 0.198973)',
      height: '63px',
      padding: '0 25px',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '21px',
      textTransform: "unset",
      ':hover': {
          backgroundColor: '#d86d5e',
      },
     }}>
        <img style={{ marginRight: "14px", objectFit: "cover", objectPosition: "center" }} src={CheckP} alt="check" />
        {params?.state?.contentButton2}
  </Button>);
}

const LandingPage = () => {
   const theme = useTheme();
   const xs = useMediaQuery(theme.breakpoints.down('xs'));
   const [state, setstate] = useState<any>(null);
   useEffect(() => {
      setstate(Data)
   }, []);

   return (
      <React.Fragment>
         <Box sx={{ width: '100%' }}>

            {/* -------------------------------------- section 1 -------------------------------------- */}
            <Box sx={{ mt: { xs: 8, md: 0}}}>
               <Grid sx={{ height: "auto" }} container={!xs} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  
                  <Grid sx={{ px:{ xs: 4, md: 0}, display: "flex", justifyContent: "center", width: "100%"}} item xs={12} md={6}>
                     <Box sx={{ mt: xs ? 0: 6, height: xs ? "100%": "fit-content", width: xs ? "100%": "70%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                        { xs ? 
                           <Typography sx={{ mb: 2, fontWeight: "600", maxWidth: "100%" }} variant="h1" component="h1">
                              {state?.titlePrimary}
                           </Typography>: 
                           <Typography sx={{ mb: 2, fontWeight: "600", maxWidth: "90%" }} variant="h1" component="h1">
                              Let’s circulate the wonder of fashion!
                              {/* Let’s circulate<br/>the wonder of <br/>fashion! */}
                           </Typography>
                        }
                        <Typography variant="body2" component="p" gutterBottom>
                           {state?.titleSecondary}
                        </Typography>
                        <Box sx={{ mt: { xs: 2, md: 4 }, display: "flex", gap: "20px" }}>
                           <Button sx={{ p: 0 }} href={APP_STORE_LINK} target="_blank" rel="noreferrer">
                              <img style={{ width: xs ? "108px" : "160px"}} className="app-icons vertical shadow" src={Apple} alt="Download on the App Store" />
                           </Button>
                           <Button sx={{ p: 0 }} href={GOOGLE_APP_LINK} target="_blank" rel="noreferrer">
                              <img style={{ width: xs ? "108px" : "160px"}} className="app-icons vertical shadow" src={Google} alt="Download on the Google play" />
                           </Button>
                        </Box>
                     </Box>
                  </Grid>

                  <Grid item sx={{ display: "flex", textAlign: "right", mt: {xs: 6, md: 0} }} xs={12} md={6}>
                     <img style={{ height: xs ? "auto" : "587px", width: "100%", objectFit: "cover", objectPosition: "center center" }} src={xs ? LandingImageMobile : LandingImage} alt="" />
                  </Grid>
               </Grid>
            </Box>

            {/* -------------------------------------- section 2 -------------------------------------- */}
            {/* -------------------------------------- For Desktop -------------------------------------- */}
            { <Grid sx={{ mt: 6, display: { xs: "none", md: "flex"}, height: "auto", backgroundColor: "#F3F595" }} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid sx={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} item xs={12} md={6}>
                  <ButtonSell state={state} xs={xs} />
                  <ButtonBuy state={state} xs={xs} />
                  <img style={{ width: "819px", objectFit: "cover", objectPosition: "center" }} src={App1} alt="" />
               </Grid>
               <Grid item sx={{ textAlign: "right", position: "relative" }} xs={12} md={6}>
                  <Box className="bg-heart"></Box>
                  <Box sx={{ mt: 20, ml: 15, width: "513px", position: "relative", zIndex: 1, textAlign: "left"}}>
                     <Typography sx={{ zIndex: 9999, fontWeight: "bold", maxWidth: "90%" }} variant="h2" component="div" gutterBottom>
                        {state?.title1}
                     </Typography>
                     <Typography variant="body2" gutterBottom>
                        {state?.description1}
                     </Typography>
                  </Box> 
               </Grid>
            </Grid> }

            {/* -------------------------------------- section 2 -------------------------------------- */}
            {/* -------------------------------------- For Mobile -------------------------------------- */} 
            <Grid sx={{ mt: 6, display: { xs: "flex", md: "none"}, height: "auto" }} container={!xs} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid sx={{ px:{ xs: 4, md: 0}, display: "flex", justifyContent: "center", width: "100%"}} item xs={12} md={6}>
                  <Box sx={{ mt: xs ? 0: 6, height: xs ? "100%": "fit-content", width: xs ? "100%": "70%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                     <Typography sx={{ zIndex: 9999, fontWeight: "bold", maxWidth: "90%" }} variant="h1" component="h1" gutterBottom>
                        {state?.title1}
                     </Typography>
                     <Typography variant="body2" gutterBottom>
                        {state?.description1}
                     </Typography>
                  </Box>
               </Grid>
            </Grid>
            <Grid sx={{ mt: 4, display: { xs: "flex", md: "none"} }} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid className="bg-heart-mobile" sx={{ py:4, backgroundColor: "#F3F595", position: "relative" }} item xs={12} md={6}>
                  <img style={{ zIndex: 999, width: "373px", objectFit: "cover", objectPosition: "center" }} src={App2} alt="" />
                  <Box sx={{ mx: 5, my: 2, textAlign: "left"}}>
                     <ButtonSell state={state} xs={xs} />
                  </Box>
                  <Box sx={{ mx: 5, my: 2, textAlign: "right"}}>
                     <ButtonBuy state={state} xs={xs} />
                  </Box>
               </Grid>
            </Grid> 

             
                              

            {/* -------------------------------------- section 3 -------------------------------------- */}
            <Grid sx={{ mt: 6, height: "auto" }} container={!xs} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid sx={{ px:{ xs: 4, md: 0}, display: "flex", justifyContent: "center", width: "100%"}} item xs={12} md={6}>
                  <Box sx={{ mt: xs ? 0: 6, height: xs ? "100%": "fit-content", width: xs ? "100%": "70%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                     { xs ?
                        <Typography sx={{ fontWeight: "bold" }} variant={ xs ? 'h1' : 'h2'} component={ xs ? 'h1' : 'h2'} gutterBottom>
                           Too busy to sell?<br/> Let’s us do all the hard<br/> work for you
                        </Typography>:
                        <Typography sx={{ fontWeight: "bold" }} variant={ xs ? 'h1' : 'h2'} component={ xs ? 'h1' : 'h2'} gutterBottom>
                           Too busy to sell?<br/> Let’s us do all the<br/> hard work for you
                        </Typography>
                     }
                     <Typography variant="body2" gutterBottom>
                        {state?.description2}
                     </Typography>
                  </Box>
               </Grid>
               <Grid item sx={{ mt: { xs: 4,md: 0 }, display: "flex", justifyContent: "center", alignItems: { xs: "flex-start", md: "center"} }} xs={12} md={6}>
                  <Button href={BUTTON3_LINK} target="_blank" sx={{
                     color: '#FFFFFF',
                     backgroundColor: '#00C19B',
                     borderRadius: '5px',
                     height: '55px',
                     padding: '0 60px',
                     fontWeight: 600,
                     fontSize: '16px',
                     lineHeight: '21px',
                     textTransform: "unset",
                     ':hover': {
                           backgroundColor: '#04ad8b',
                     }
                  }}>
                     {state?.contentButton3}                  
                  </Button>
               </Grid>
            </Grid>
            {/* -------------------------------------- section 4 -------------------------------------- */}
            <Box sx={{ mt: { xs: 6, md: 0 }, height: xs ? "280px" : "420px", textAlign: "center"}}>
               <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: {xs: "flex-start", md: "center"} }}>
                  <Typography sx={{ fontWeight: "bold", maxWidth: "90%" }} variant={ xs ? 'h1' : 'h2'} component="div" gutterBottom>
                     {state?.title3}
                  </Typography>
                  <Box sx={{ mt: {xs: 2, md: 0 },  display: "flex", gap: "20px" }}>
                     <Button sx={{ p: 0 }} href={APP_STORE_LINK} target="_blank" rel="noreferrer">
                        <img style={{ width: xs ? "130px" : "185px"}} className="app-icons vertical shadow" src={Apple} alt="Download on the App Store" />
                     </Button>
                     <Button sx={{ p: 0 }} href={GOOGLE_APP_LINK} target="_blank" rel="noreferrer">
                        <img style={{ width: xs ? "130px" : "185px"}} className="app-icons vertical shadow" src={Google} alt="Download on the Google play" />
                     </Button>
                  </Box>
               </Box>
            </Box>
         </Box>
         <Footer />
      </React.Fragment>
   )
}

export default LandingPage;
