import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logo from '../assets/images/Logo.png'
import Remove from '../assets/images/Remove.png'
import { SUPPORT_LINK,LINE_LINK, BUTTON3_LINK, FAQ_LINK } from '../constans/app.constans';

const LogoElement = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Typography
      variant="h6"
      noWrap
      component="a"
      href="/"
      sx={{
        mr: 2,
        fontSize: xs ? '16px' : '24px',
        fontWeight: 700,
        display: 'flex',
        color: 'inherit',
        textDecoration: 'none',
        alignItems: "center"
      }}
    >
      <img style={{ marginRight: xs ? "6px" : "10px", width: xs ? "20px" : "40px", objectFit: "cover", objectPosition: "center" }} src={Logo} alt="Logo" />
      hangles
    </Typography>
  );
}

const ResponsiveAppBar = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (link: string | null = null) => {
    if (link) {
      window.open(link, '_blank');
    }

    setAnchorElNav(null);
  };


  return (
    <AppBar position="static" sx={{ px: 4, background: "#FFFFFF", boxShadow: "none", color: "#19191B"}}>
        <Toolbar sx={{ mt: xs ? 2 : 0, px: 2, height: xs ? "40" : "84px", boxShadow: xs ? "0px 1px 10px rgba(0, 0, 0, 0.1)" : "none", borderRadius: xs ? '3px' : 0 }} disableGutters>
          {/* Desktop */}
          <Box sx={{ display: {xs: "none", md: "flex"}}}>
            <LogoElement />
          </Box>

          {/* Mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu()}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Button sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }} onClick={() => handleCloseNavMenu()}>
                <img style={{ marginRight: xs ? "6px" : "10px", width: "30px", objectFit: "cover", objectPosition: "center" }} src={Remove} alt="Remove" />
              </Button>
              <MenuItem onClick={() => handleCloseNavMenu(FAQ_LINK)}>
                <Typography sx={{ width: "120px", color: "#1A2542", fontSize: "20px", fontWeight: 600 }} textAlign="left">FAQs</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleCloseNavMenu(LINE_LINK)}>
                <Typography sx={{ width: "120px", color: "#1A2542", fontSize: "20px", fontWeight: 600 }} textAlign="left">Support</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: {xs: "flex", md: "none"}}}>
            <LogoElement />
          </Box>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>
          
          {/* Desktop */}
          <Box sx={{ flexGrow: 0 }}>
            <Stack sx={{ justifyContent: "center" }} direction="row" spacing={2}>
              <Button href={FAQ_LINK} sx={{ display: { xs: "none", md: "flex"}, color: "inherit", fontSize: "16px", fontWeight: 500, textTransform: "unset" }} target="_blank">FAQs</Button>
              <Button href={LINE_LINK} sx={{ display: { xs: "none", md: "flex"}, color: "inherit", fontSize: "16px", fontWeight: 500, textTransform: "unset" }} target="_blank">Support</Button>
              <Button href={BUTTON3_LINK} target="_blank" sx={{ 
                color: "#FFFFFF", 
                fontSize: "16px", 
                lineHeight: '21px',
                fontWeight: 500, 
                backgroundColor: '#00C19B',
                borderRadius: xs ? '3px' : '5px',
                height: xs ? '34px' : '55px',
                padding: xs ? '0 20px' : '0 40px',
                textTransform: "unset",
                ':hover': {
                  backgroundColor: '#04ad8b'
                } }}>
                  { xs ? 'Get start' : 'Get Started' }
              </Button>
            </Stack>
          </Box>
        </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;