
import { createTheme } from "@mui/material/styles";


const CUSTOM_THEME = () => ({
    typography: {
      fontFamily: "'IBM Plex Sans', sans-serif;",
    },
    palette: {
        text: {
            primary: '#FFFFFF',
            secondary: '#15194C',
            disabled: ''
        },
        secondary: {
            main: '#F8F8F8'
        }
    } 
});

const theme = createTheme(CUSTOM_THEME());

theme.typography.h1 = {
    fontSize: '25px',
    lineHeight: '25px',
    '@media (min-width:600px)': {
        fontSize: '48px',
        lineHeight: '54px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '68px',
        lineHeight: '72px',
    },
}

theme.typography.body2 = {
    color: '#6C6C72',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
        color: '#6C6C72',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '21px',
        lineHeight: '39px',
    },
}

export default theme;
